<template>
  <div class="propertyMgEagleeyeForm-container">
    <form-panel ref="formPanel" :form="form"  v-bind="submitConfig" :submitUrl="submitUrl" submitText="保存" :submitBefore="submitBefore" :footerShow="footerShow">
      <col2-block title="基础信息">
        <col2-item>
          <el-form-item label="鹰眼组名称">
            <span>{{form.groupName}}</span>
          </el-form-item>
          <el-form-item label="归属项目">
            <span>{{communityName}}</span>
          </el-form-item>
          <el-form-item label="使用者">
            <span>{{userInfo}}</span>
          </el-form-item>
        </col2-item>
      </col2-block>
      <col2-block title="操作信息" v-if="isShowOps">
        <col2-item>
          <el-form-item label="状态" prop="status" :rules="[{ required: true, message: '请选择状态', trigger: 'change' }]">
            <v-select v-model="form.status" :options="statusOps"></v-select>
          </el-form-item>
          <el-form-item label="描述" v-if="form.status === 3 || form.status === 4" :rules="[{ required: true, message: '请输入描述', trigger: 'blur' }]">
            <v-textarea v-model="form.remark" type="textarea" :maxlength="200"></v-textarea>
          </el-form-item>
          <el-form-item label="描述" v-else>
            <v-textarea v-model="form.remark" type="textarea" :maxlength="200"></v-textarea>
          </el-form-item>
        </col2-item>
      </col2-block>
    </form-panel>
  </div>
</template>
<script>
import * as allUsageUrl from './api'
import { statusOps, _sex, _userType, closeStatus } from './map'
import { Col2Block, Col2Item } from 'components/bussiness'
export default {
  components: {
    Col2Block,
    Col2Item
  },
  data () {
    return {
      isShowOps: false,
      footerShow: false,
      form: {
        id: '',
        userId: '',
        groupType: undefined,
        status: undefined,
        remark: '',
        groupId: '',
        groupName: '',
        dataObject: ''
      },
      communityName: '',
      userInfo: '',
      statusOps: [],
      submitConfig: {
        submitContentType: 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      submitUrl: allUsageUrl.updateUsageUrl
    }
  },
  created () {
    this.$setBreadcrumb('编辑')
    this.getDetailData()
    this.form.id = this.$route.query.id
  },
  methods: {
    getDetailData () {
      const _this = this
      _this.$axios.post(allUsageUrl.detailUsageUrl, { id: this.$route.query.id })
        .then(res => {
          let returnStatus = String(res.status)
          if (returnStatus === '100') {
            const data = res.data
            _this.form.userId = data.userId
            _this.form.groupId = data.groupId
            _this.form.groupType = data.groupType
            const status = Number(data.status)
            _this.statusOps = status === 2 ? closeStatus : statusOps
            _this.isShowOps = status !== 4
            _this.footerShow = status !== 4
            _this.form.status = status === 2 ? 4 : status
            _this.form.groupName = data.groupName
            _this.form.dataObject = data.dataObject
            _this.communityName = data.communityName
            const groupType = String(data.groupType)
            const userType = _userType[data.userType]
            const sex = _sex[data.sex]
            const separator = '、'
            const info = data.userName + '(' + sex + separator + userType + separator + data.phoneNum + separator + data.address + ')'
            const datas = data.userName + '(' + data.role + separator + data.phoneNum + separator + '生活服务中心' + ')'
            _this.userInfo = groupType === '1' ? info : datas
          }
        })
    },
    submitBefore (data) {
      if ((data.status === 3 || data.status === 4) && !data.remark) {
        alert('请输入描述!')
        return false
      }
      return true
    }
  }
}
</script>
