import { generateMapByOpts } from 'common/utils'

// 状态
const statusOps = [
  {
    text: '待审核',
    value: 1
  },
  {
    text: '已通过',
    value: 2
  },
  {
    text: '已拒绝',
    value: 3
  },
  {
    text: '已关闭',
    value: 4
  }
]
const closeStatus = [
  {
    text: '已关闭',
    value: 4
  }
]

const statusMap = generateMapByOpts(statusOps)

// 性别
const _sex = {
  1: '男',
  2: '女'
}
// 身份
const _userType = {
  1: '业主',
  2: '家属',
  3: '租客'
}
// 使用者类型
const groupTypeOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '用户',
    value: 1
  }, {
    text: '物管',
    value: 2
  }
]
// 状态
const searchStatusOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '待审核',
    value: 1
  }, {
    text: '已通过',
    value: 2
  }, {
    text: '已拒绝',
    value: 3
  }, {
    text: '已关闭',
    value: 4
  }
]

export {
  statusOps,
  statusMap,
  _sex,
  _userType,
  closeStatus,
  groupTypeOps,
  searchStatusOps
}
