// 获取列表
const getListURL = `${API_CONFIG.baseURL}serverEagleeyeAction!usageList.action`
// 导出列表
const exportListURL = `${API_CONFIG.baseURL}serverEagleeyeAction!exportUsageToExcel.action`
// 批量操作
const batchURL = `${API_CONFIG.baseURL}smUserAccountAction!batchUpdateRechargeCardStatus.action`
// 批量通过
const dealOnBatchUrl = `${API_CONFIG.baseURL}serverEagleeyeAction!dealOnBatch.action`
// 详情Update
const updateUsageUrl = `${API_CONFIG.baseURL}serverEagleeyeAction!updateUsage.action`
// detail
const detailUsageUrl = `${API_CONFIG.baseURL}serverEagleeyeAction!queryUsage.action`

export {
  getListURL,
  exportListURL,
  batchURL,
  dealOnBatchUrl,
  updateUsageUrl,
  detailUsageUrl
}
